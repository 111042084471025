<template>
  <CHeader position="sticky" class="mb-4 px-lg-1 px-0">
    <CContainer fluid>
      <!-- left section -->
      <div class="d-flex align-items-center">
        <!-- sidebar toggler -->
        <CHeaderToggler
          class="ps-0"
          @click="$store.commit('TOGGLE_SIDEBAR')"
          v-if="member"
        >
          <CIcon icon="cil-menu" size="lg" />
        </CHeaderToggler>

        <!-- hidden sidebar logo -->
        <div class="d-flex align-items-center" v-if="!sidebarVisible">
          <router-link
            to="/"
            class="d-flex align-items-center text-decoration-none"
          >
            <!-- header big logo -->
            <CHeaderBrand class="d-none d-sm-flex me-3 my-header-logo" to="/">
              <img
                :src="
                  theme === 'default'
                    ? getImgUrl('brand/copyright-capital-logo.png')
                    : getImgUrl('brand/copyright-capital-logo.png')
                "
                alt="copyright capital logo"
                class="header-big-logo"
              />
            </CHeaderBrand>

            <!-- header small logo -->
            <CHeaderBrand class="d-sm-none me-3 my-header-logo" to="/">
              <img
                :src="
                  theme === 'default'
                    ? getImgUrl('brand/copyright-capital-logo-mini.png')
                    : getImgUrl('brand/copyright-capital-logo-mini.png')
                "
                alt="copyright capital mini logo"
                class="header-small-logo"
              />
            </CHeaderBrand>
          </router-link>

          <div class="growth-pipe-header" v-if="productName"></div>
          <div class="growth-text text-center fw-lighter ps-3">
            {{ productName }}
          </div>
        </div>

        <!-- <CHeaderNav class="d-none d-md-flex me-auto">
          <CNavItem>
            <CNavLink href="/"> Home </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">Users</CNavLink>
          </CNavItem>
        </CHeaderNav> -->
      </div>

      <!-- right section -->
      <div
        class="header-nav flex-wrap align-items-center ms-auto justify-content-end"
      >
        <!-- dev only switch -->
        <div
          v-if="showDevAclSwitch"
          class="d-flex rounded border px-2 py-1 me-3 bg-gradient bg-opacity-75"
          :class="
            acl?.includes('DEV_MODE')
              ? 'bg-info border-primary text-white'
              : 'bg-light'
          "
        >
          <div
            class="d-flex align-items-center form-control-remove-margin-bottom"
          >
            <!-- <router-link to="/demo/vuex-test" class="me-2">vuex</router-link> -->
            <CFormSwitch
              class="flex-row-reverse bg-success"
              label="dev"
              id="dev-acl"
              :checked="acl?.includes('DEV_MODE')"
              @change="TOGGLE_DEV_ACL({ show: $event.target.checked })"
            />
          </div>
        </div>

        <div
          v-if="showDevAppModeSwitch"
          class="d-flex rounded border px-2 py-1 me-3 bg-gradient bg-light bg-opacity-75 app-mode-toggle"
        >
          <div
            class="d-flex align-items-center form-control-remove-margin-bottom"
          >
            <span class="pe-2">operations</span>
            <CFormSwitch
              class="flex-row-reverse bg-success"
              label="creators"
              id="localhost-app-mode"
              :checked="appMode === appModes.creators"
              @change="onChangeAppMode($event)"
            />
          </div>
        </div>

        <!-- visible only lg and upper -->
        <div class="d-none d-lg-flex">
          <!-- theme selector -->
          <div class="me-3 d-flex align-items-center theme-selector-wrapper">
            <AppHeaderThemeSelector />
          </div>

          <!-- growth admin user select -->
          <!-- <div class="me-3" v-if="isAdmin">
            <AppHeaderAdminMemberListDropdown />
          </div> -->
        </div>

        <!-- user dropdown - profile, settings, logout etc  -->
        <div v-if="member">
          <AppHeaderDropdownAccnt />
        </div>
      </div>
    </CContainer>
    <!-- <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer> -->
  </CHeader>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

// import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
// import AppHeaderAdminMemberListDropdown from './AppHeaderAdminMemberListDropdown'
import AppHeaderThemeSelector from './AppHeaderThemeSelector'

import getImgUrl from '@/helpers/get-img-helper'
import { appModes } from '@/helpers/constants'

export default {
  name: 'AppHeader',
  components: {
    // AppBreadcrumb,
    AppHeaderDropdownAccnt,
    // AppHeaderAdminMemberListDropdown,
    AppHeaderThemeSelector,
  },
  data() {
    return {
      getImgUrl,
      appModes,
    }
  },
  methods: {
    ...mapMutations('memberModule', ['TOGGLE_DEV_ACL']),
    ...mapActions('memberModule', ['SET_APP_MODE_FOR_DEVELOPMENT']),
    onChangeAppMode(e) {
      const selectedAppMode = e.target.checked
        ? appModes.creators
        : appModes.operations

      if (window.location.host.startsWith('localhost')) {
        this.SET_APP_MODE_FOR_DEVELOPMENT(selectedAppMode)
        return
      }
      if (process.env.VUE_APP_MODE === 'testing') {
        window.location.href = `https://${selectedAppMode.toLocaleLowerCase()}-test.copyrightcapital.com`
      }
    },
  },
  computed: {
    ...mapState('memberModule', [
      'acl',
      'isAdmin',
      'memberStatus',
      'productName',
      'member',
      'appMode',
    ]),
    ...mapState(['sidebarVisible', 'theme']),
    showDevAclSwitch() {
      return this.acl?.includes('ROLE_DEVELOPER')
    },
    showDevAppModeSwitch() {
      return (
        window.location.host.startsWith('localhost') ||
        process.env.VUE_APP_MODE === 'testing'
      )
    },
  },
}
</script>

<style lang="scss">
.header {
  .my-header-logo {
    height: 42px;
    display: flex;
    align-items: center;
    padding-bottom: 7px;
  }
  .growth-pipe-header {
    padding-bottom: 4px;
    border-left: 1px solid;
    height: 22px;
  }
  .header-big-logo {
    width: 110px;
  }
  .header-small-logo {
    width: 30px;
  }
  .theme-selector-wrapper {
    padding-top: 1px;
  }
  .form-check-input:checked {
    background-color: #ff6600;
    border-color: #ff6600;
  }
  .app-mode-toggle {
    .form-check-input:checked {
      background-color: #ff6600;
      border-color: #ff6600;
    }
    .form-check-input:not(:checked) {
      background-color: #238ccd;
      border-color: #238ccd;
      --cui-form-switch-bg: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%28255, 255, 255, 0.87%29%27/%3e%3c/svg%3e');
    }
  }
}

.dark-theme {
  .growth-pipe-header {
    border-color: white;
  }
}
</style>
