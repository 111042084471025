const state = {
  pageName: null,
  pageLink: null,
  pairedDate: null,
  totalFollowers: null,
  totalPosts: null,
  totalLifetimeReach: null,
  totalProfileViews: null,
  instagramAccountId: null,
}

const mutations = {
  SET_ACCOUNT(state, payload) {
    if ('pageName' in payload) {
      state.pageName = payload.pageName
    }
    if ('pageLink' in payload) {
      state.pageLink = payload.pageLink
    }
    if ('pairedDate' in payload) {
      state.pairedDate = payload.pairedDate
    }
    if ('totalFollowers' in payload) {
      state.totalFollowers = payload.totalFollowers
    }
    if ('totalPosts' in payload) {
      state.totalPosts = payload.totalPosts
    }
    if ('totalLifetimeReach' in payload) {
      state.totalLifetimeReach = payload.totalLifetimeReach
    }
    if ('totalProfileViews' in payload) {
      state.totalProfileViews = payload.totalProfileViews
    }
    if ('instagramAccountId' in payload) {
      state.pageLink = payload.instagramAccountId
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}
