import httpClient from './http-client'
import { Auth } from 'aws-amplify'

// for local acl developments, uncomment the 2 lines below
import store from '@/store'
import { appModes } from '@/helpers/constants'

const getMember = (bypassCache = true) =>
  Auth.currentAuthenticatedUser({
    // Optional, By default is false. If set to true,
    // this call will send a request to Cognito to get the latest user data
    bypassCache: bypassCache,
  })

const register = (email, password, phoneNumber, metaUserId) =>
  Auth.signUp({
    username: email,
    password,
    attributes: {
      email, // optional
      phone_number: phoneNumber, // optional - E.164 number convention    }
      ...(metaUserId && { 'custom:meta_user_id': metaUserId }),
      // other custom attributes
    },
    autoSignIn: {
      // optional - enables auto sign in after user is confirmed
      enabled: false,
    },
  })

const login = (email, password) => Auth.signIn(email, password)

const getAcl = async () => {
  if (store.state?.memberModule?.appMode === appModes.creators) {
    return httpClient.get('getCreatorUserAclPermissions')
  }

  return httpClient.get('getUserAclPermissions')

  // for local development remove comments below:
  // if (store.state?.memberModule?.appMode === appModes.creators) {
  //   return httpClient.get(
  //     location.origin + '/fake-data/api/member/creators-acl',
  //   )
  // }
  // return httpClient.get(
  //   location.origin + '/fake-data/api/member/operations-acl',
  // )
}

const confirmSignUp = (email, code) => Auth.confirmSignUp(email, code)

// Re-send sign up confirmation code
const resendSignUpConfirmationCode = (email) => Auth.resendSignUp(email)

export default {
  getMember,
  login,
  getAcl,
  register,
  confirmSignUp,
  resendSignUpConfirmationCode,
}
