// check if value is an object with dataType key and return the data (if string, make it lowercase for sort)
function getValue(val) {
  if (val?.dataType) {
    return val.dataType === 'STRING' ? val.value.toLowerCase() : val.value
  } else {
    return typeof val === 'string' ? val.toLowerCase() : val
  }
}

/**
 *
 * @param {Object} e CSmartTable sort event
 * @param {Array} data array of data to sort
 */
let arraySorter = function (e, data) {
  let sortedItems = []
  let errorHappenedWhileFindingValue = false
  function compare(a, b) {
    a = a[e.column]
    b = b[e.column]

    const _a = getValue(a)
    const _b = getValue(b)

    if (typeof a === 'undefined' || typeof b === 'undefined') {
      errorHappenedWhileFindingValue = true
      return 0
    }

    if (typeof _a === 'string') {
      return _a.localeCompare(_b)
    }

    if (_a < _b) {
      return -1
    }
    if (_a > _b) {
      return 1
    }
    return 0
  }
  sortedItems = data.sort(compare)

  if (e.state === 'desc') {
    sortedItems.reverse()
  }

  if (errorHappenedWhileFindingValue) {
    console.error(
      `array-sorter.js error. Cannot find "${e?.column}" key in provided array. Please check if your array has "${e?.column}" key. Sort is not applied.`,
      '\n',
      { e: e },
      '\n',
      { data: data },
    )
  }

  return sortedItems
}

export default arraySorter
