const getSelectedMember = () => {
  return localStorage.getItem('selectedMemberId')
}

const setSelectedMember = (selectedMemberId) => {
  localStorage.setItem('selectedMemberId', selectedMemberId)
}

const clearSelectedMember = () => {
  localStorage.removeItem('selectedMemberId')
}

export default {
  getSelectedMember,
  setSelectedMember,
  clearSelectedMember,
}
