import dayjs from 'dayjs'
dayjs.Ls.en.weekStart = 1 // to make monday as first day of week: https://github.com/iamkun/dayjs/issues/215#issuecomment-1039948830

export function useGlobalDayjs() {
  return dayjs
}

export function provideAppDayjs(app) {
  // Create the global container

  // Provide using Vue dependency injection
  app.provide(dayjs)

  // Bind to the global object so it can be called with this.$dayjs
  app.config.globalProperties.$dayjs = dayjs
}

// usage examples
// for js files:
// import { useGlobalDayjs } from '@/plugins/dayjs'
// let dayjs = useGlobalDayjs()
// console.log('ahey', dayjs())

// for vue script section options API
// console.log('now:', this.$dayjs())

// for template section
// <div> {{ $dayjs() }} </div>

// for composition api
// import { useGlobalDayjs } from '@/plugins/mitt'
// export default {
//   setup() {
//     const $dayjs = useGlobalDayjs()
//     ...
//   }
//   ...
// }

// more: https://stackoverflow.com/questions/66559331/how-to-properly-use-dayjs-inside-vue-3-app-and-component
