import getImgUrl from './get-img-helper'

const getImgUrlByDSType = (dSType) => {
  let imgUrl
  if (dSType === 'GA' || dSType === 'SM_GA') {
    imgUrl = 'brand-logos/google-analytics.svg'
  }
  if (dSType === 'GSC' || dSType === 'SM_GSC') {
    imgUrl = 'brand-logos/google.svg'
  }
  if (dSType === 'GADS' || dSType === 'SM_GADS') {
    imgUrl = 'brand-logos/google-ads.svg'
  }
  if (dSType === 'FBA' || dSType === 'SM_FBA') {
    imgUrl = 'brand-logos/facebook.svg'
  }
  if (dSType === 'SHPY' || dSType === 'SM_SHPY') {
    imgUrl = 'brand-logos/shopify.svg'
  }
  if (dSType === 'TY' || dSType === 'SM_TY') {
    imgUrl = 'brand-logos/trendyol.png'
  }
  if (dSType === 'YT' || dSType === 'SM_YT' || dSType === 'youtube') {
    imgUrl = 'brand-logos/youtube.svg'
  }

  if (imgUrl) {
    return getImgUrl(imgUrl)
  }
  return getImgUrl('images/no-image.svg')
}
const getFullDSName = (dSType) => {
  let fullDSName

  if (dSType === 'GA' || dSType === 'SM_GA') {
    fullDSName = 'Google Analytics'
  }
  if (dSType === 'GSC' || dSType === 'SM_GSC') {
    fullDSName = 'Google Search Console'
  }
  if (dSType === 'GADS' || dSType === 'SM_GADS') {
    fullDSName = 'Google Ads'
  }
  if (dSType === 'FBA' || dSType === 'SM_FBA') {
    fullDSName = 'Facebook Ads'
  }
  if (dSType === 'SHPY' || dSType === 'SM_SHPY') {
    fullDSName = 'Shopify'
  }
  if (dSType === 'TY' || dSType === 'SM_TY') {
    fullDSName = 'Trendyol'
  }
  if (dSType === 'YT' || dSType === 'SM_YT') {
    fullDSName = 'Youtube'
  }

  return fullDSName || dSType
}

export default { getFullDSName, getImgUrlByDSType }
