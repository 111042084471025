import axios from 'axios'
// import adminMemberHandler from '@/helpers/admin-member-handler'
// import { cacheAdapterEnhancer } from 'axios-extensions'

// to get token
import { Auth } from 'aws-amplify'

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  // timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
  // adapter: cacheAdapterEnhancer(axios.defaults.adapter),
})

const checkIfCCRequest = (config) => {
  if (
    config.url.includes('copyrightcapital.com') ||
    config.url.includes('api-test.copyrightcapital.com') ||
    config.url.includes('amazonaws.com') ||
    config.url.includes('d5aoqrjrzjx42.amplifyapp.com') ||
    config.url.includes('execute-api.eu-central-1.amazonaws.com') ||
    config.url.includes('localhost') ||
    !config.url.includes('http') // if url has http, it's probably not an cc request (maybe google). bc we already have http for our baseUrl
  ) {
    return true
  }
  return false
}

const getAuthToken = async (config) => {
  // url needs to have a path like /public/, then we can remove this ugly workaround (config.public) from here and creator-dashboard-api.js
  if (config.public !== true && checkIfCCRequest(config)) {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
    return token
  }
  return false
}

// check and set auth, admin and growth releated headers
const requestInterceptors = async (config) => {
  config.baseURL = process.env.VUE_APP_API_BASE_URL

  // get data from public/fake-data folder instead of api
  if (config.url.includes('fake-data')) {
    config.method = 'get'
    config.url = config.url + '.json'
  }

  // for google requests, use provided config.query token instead of default
  if (
    config.url.startsWith('https://www.googleapis.com/') &&
    config.query?.Authorization
  ) {
    config.headers['Authorization'] = config.query.Authorization
    return config
  }

  // if request is not for Copyright Capital, remove MEMBER_ID from headers
  if (!checkIfCCRequest(config)) {
    delete config.headers['MEMBER_ID']
    return config
  }

  let token = await getAuthToken(config)
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token
  } else {
    delete config.headers['Authorization']
    return config
  }

  // TODO: This is an ugly way to structure URL however our URL structure is not yet setup correctly, till we implement same structure for every lambda, we need to parse url in a bad way
  if (config.url.includes('api-test')) {
    const url = new URL(config.baseURL)

    const pathSegments = url.pathname.split('/').filter(Boolean)

    pathSegments.pop()

    url.pathname = pathSegments.join('/')

    if (url.pathname && !url.pathname.endsWith('/')) {
      url.pathname += '/'
    }

    config.baseURL = url.toString()
  }

  // TODO will be uncommented after admin is implemented
  // add MEMBER_ID for admin
  // let selectedMember = adminMemberHandler.getSelectedMember()
  // if (selectedMember) {
  //   config.headers['MEMBER_ID'] = selectedMember
  // }

  return config
}

httpClient.interceptors.request.use(requestInterceptors)

// Interceptor for responses
// const responseInterceptor = (response) => {
//   switch (response.status) {
//     case 200:
//       // yay!
//       break
//     // any other cases
//     default:
//     // default case
//   }

//   // check if payload json is valid
//   try {
//     JSON.parse(response.data)
//   } catch (err) {
//     console.error(
//       'Response JSON seems not valid. Please check it.',
//       '\n',
//       { responseData: response.data },
//       '\n',
//       { rawErrorObejct: err },
//     )

//     let handledResponse = {
//       response: {
//         ...response,
//         data: {
//           payload: {
//             _brokenResponseDataAddedByHttpClientJs: response.data,
//           },
//           errors: [
//             {
//               id: 'invalid_response_json',
//               message: 'Invalid response JSON',
//             },
//           ],
//         },
//       },
//     }
//     throw handledResponse
//   }
//   return response
// }

// interceptor to catch errors
// const errorInterceptor = (error) => {
//   // check if it's a server error
//   if (!error.response) {
//     toast.warning('Network/Server error')
//     return Promise.reject(error)
//   }
// }

// httpClient.interceptors.response.use(
//   responseInterceptor /* , errorInterceptor */,
// )

export default httpClient
