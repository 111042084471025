const state = {
  count: 0,
}

const mutations = {
  INCREMENT(state) {
    state.count++
  },
}

const actions = {
  INCREMENT_ASYNC({ commit }) {
    setTimeout(() => {
      commit('INCREMENT')
    }, 1000)
  },
  INCREMENT_ASYNC_WITH_PROMISE({ commit }) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('INCREMENT')
        resolve()
      }, 1000)
    })
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
