<template>
  <v-select
    label="dataSource"
    class="mm-select"
    placeholder="Select a data source"
    :clearable="false"
    :filterable="false"
    :searchable="false"
    v-bind="$attrs"
  >
    <template v-slot:option="option">
      <div class="d-flex align-items-center">
        <img
          :src="
            dsHelper.getImgUrlByDSType(
              option.datasourceType || option.dataSourceType,
            )
          "
          class="me-2"
          width="15"
          v-tooltip="
            dsHelper.getFullDSName(
              option.datasourceType || option.dataSourceType,
            )
          "
        />
        <div>
          {{ option.dispName }}
        </div>
      </div>
    </template>
    <template v-slot:selected-option="option">
      <div class="d-flex align-items-center selected">
        <img
          :src="
            dsHelper.getImgUrlByDSType(
              option.datasourceType || option.dataSourceType,
            )
          "
          class="me-2"
          width="15"
          v-tooltip="
            dsHelper.getFullDSName(
              option.datasourceType || option.dataSourceType,
            )
          "
        />
        <div>
          {{ option.dispName }}
        </div>
      </div>
    </template>
    <template #open-indicator="{ attributes }">
      <span v-bind="attributes" class="dropdown-icon"></span>
    </template>
    <template v-for="(_, slot) of $slots" v-slot:[slot]="scope"
      ><slot :name="slot" v-bind="scope"
    /></template>
  </v-select>
</template>

<script>
import dsHelper from '@/helpers/ds-helper'

export default {
  props: {},
  data: () => {
    return {
      dsHelper,
    }
  },
}
</script>

<style lang="scss">
.dropdown-icon {
  $form-select-indicator-color: #636f83;
  background-color: var(--cui-form-select-bg, #fff);
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23636f83%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e');
  background-repeat: no-repeat;
  background-size: 16px 12px;
  display: block;
  width: 16px;
  height: 12px;
}

.dark-theme {
  .dropdown-icon {
    $form-select-indicator-color: #fff;
    background-color: transparent;
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23636f83%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e');
  }
}
</style>
