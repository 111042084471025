const state = {
  channelName: null,
  channelLink: null,
  mostRecentDataExtractedMTD: null,
  refreshMTDData: null,
  channelCreationDate: null,
  channelAge: null,
  category: null,
  totalSubscribers: null,
  totalVideos: null,
  totalLifetimeViews: null,
  loading: null,
}

const mutations = {
  INVALIDATE_CHANNEL_STATE(state) {
    state.channelName = null
    state.channelLink = null
  },
  SET_CHANNEL(state, payload) {
    if ('channelName' in payload) {
      state.channelName = payload.channelName
    }
    if ('channelLink' in payload) {
      state.channelLink = payload.channelLink
    }
    if ('mostRecentDataExtractedMTD' in payload) {
      state.mostRecentDataExtractedMTD = payload.mostRecentDataExtractedMTD
    }
    if ('refreshMTDData' in payload) {
      state.refreshMTDData = payload.refreshMTDData
    }
    if ('channelCreationDate' in payload) {
      state.channelCreationDate = payload.channelCreationDate
    }
    if ('channelAge' in payload) {
      state.channelAge = payload.channelAge
    }
    if ('category' in payload) {
      state.category = payload.category
    }
    if ('totalSubscribers' in payload) {
      state.totalSubscribers = payload.totalSubscribers
    }
    if ('totalVideos' in payload) {
      state.totalVideos = payload.totalVideos
    }
    if ('totalLifetimeViews' in payload) {
      state.totalLifetimeViews = payload.totalLifetimeViews
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}
