import mitt from 'mitt'

const emitter = mitt()

export function useGlobalMitt() {
  return emitter
}

export function provideMitt(app) {
  // Create the global container

  // Provide using Vue dependency injection
  app.provide(emitter)

  // Bind to the global object so it can be called with this.$emitter
  app.config.globalProperties.$emitter = emitter
}

// usage examples
// for js files:
// import { useGlobalMitt } from '@/plugins/mitt'
// const $mitt = useGlobalMitt()
// $mitt.on('my-event-name', (e) => {
//   console.log('heloooo', e)
// })

// for vue script section options API
// this.$emitter.emit('my-event-name', 'hebele')
// this.$emitter.on('my-event-name', (e) => {
//   console.log('my-event-name, value:', e)
// })

// for composition api
// import { useGlobalMitt } from '@/plugins/mitt'
// export default {
//   setup() {
//     const $emitter = useGlobalMitt()
//     ...
//   }
//   ...
// }

// more: https://stackoverflow.com/questions/63471824/vue-js-3-event-bus
