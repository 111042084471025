// adds timezone at the end of the given ISO string like +03:00  (2022-03-14T03:04:28.541+03:00)

const toISOLocal = function (d) {
  var z = (n) => ('0' + n).slice(-2)
  var zz = (n) => ('00' + n).slice(-3)
  var off = d.getTimezoneOffset()
  var sign = off > 0 ? '-' : '+'
  off = Math.abs(off)

  return (
    d.getFullYear() +
    '-' +
    z(d.getMonth() + 1) +
    '-' +
    z(d.getDate()) +
    'T' +
    z(d.getHours()) +
    ':' +
    z(d.getMinutes()) +
    ':' +
    z(d.getSeconds()) +
    '.' +
    zz(d.getMilliseconds()) +
    sign +
    z((off / 60) | 0) +
    ':' +
    z(off % 60)
  )
}

export default toISOLocal
