<template>
  <div>
    <template v-if="showInCard">
      <CCard>
        <CCardBody>
          <CAlert :color="color" class="d-flex align-items-center mb-0">
            <CIcon
              :icon="icon"
              class="flex-shrink-0 me-2"
              width="24"
              height="24"
            />
            <slot> No data available </slot>
          </CAlert>
        </CCardBody>
      </CCard>
    </template>
    <template v-else>
      <CAlert :color="color" class="d-flex align-items-center mb-0">
        <CIcon :icon="icon" class="flex-shrink-0 me-2" width="24" height="24" />
        <slot> No data available </slot>
      </CAlert>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'cil-warning',
    },
    color: {
      type: String,
      default: 'warning',
    },
    showInCard: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
