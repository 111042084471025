import { Amplify } from 'aws-amplify'
import store from '@/store'
import { appModes } from './constants'

const config = {
  [appModes.creators]: {
    userPoolId: process.env.VUE_APP_CREATORS_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_CREATORS_CLIENT_ID,
  },
  [appModes.operations]: {
    userPoolId: process.env.VUE_APP_OPERATIONS_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_OPERATIONS_CLIENT_ID,
  },
}
const configureAmplify = () => {
  const appMode = store.state?.memberModule?.appMode

  if (!appMode) {
    console.error('appMode is required! Please check member module.')
  }

  Amplify.configure({
    Auth: {
      userPoolId: config[appMode].userPoolId,
      userPoolWebClientId: config[appMode].userPoolWebClientId,
      region: 'eu-central-1',
    },
  })
}

export { configureAmplify }
