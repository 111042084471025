import { createI18n } from 'vue-i18n'
// import { createI18n } from 'vue-i18n/index'

// translations
import enUsMessages from '@/plugins/i18n/messages/en-US.json'
import jaJpMessages from '@/plugins/i18n/messages/ja-JP.json'
import enGbMessages from '@/plugins/i18n/messages/en-GB.json'
import trTrMessages from '@/plugins/i18n/messages/tr-TR.json'

// const language = {
//   detected: navigator.language || navigator.languages[0],
//   available: navigator.languages || [navigator.language],
// }

// const browserLanguage = language.detected.substring(0, 2)
const messages = {
  'en-US': enUsMessages,
  'ja-JP': jaJpMessages,
  'en-GB': enGbMessages,
  'tr-TR': trTrMessages,
}

const datetimeFormats = {
  'tr-TR': {
    monthly: {
      year: 'numeric',
      month: 'short',
    },
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    medium: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  'en-GB': {
    monthly: {
      year: 'numeric',
      month: 'short',
    },
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    medium: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  'en-US': {
    monthly: {
      year: 'numeric',
      month: 'short',
    },
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    medium: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  'ja-JP': {
    monthly: {
      year: 'numeric',
      month: 'short',
    },
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    medium: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
  },
}

const numberFormats = {
  'tr-TR': {
    currency: {
      style: 'currency',
      currency: 'EUR',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
    unformatted: {
      useGrouping: false,
    },
  },
  'en-GB': {
    currency: {
      style: 'currency',
      currency: 'EUR',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
    unformatted: {
      useGrouping: false,
    },
  },
  'en-US': {
    currency: {
      style: 'currency',
      currency: 'USD',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
    unformatted: {
      useGrouping: false,
    },
  },
  'ja-JP': {
    currency: {
      style: 'currency',
      currency: 'JPY',
      useGrouping: true,
      currencyDisplay: 'symbol',
    },
    decimal: {
      style: 'decimal',
      minimumSignificantDigits: 3,
      maximumSignificantDigits: 5,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
    unformatted: {
      useGrouping: false,
    },
  },
}

// checks users language and check if that language has formats in this file
const getCurrentLanguage = function () {
  let browserLanguage = navigator?.language
  if (browserLanguage === 'tr') {
    browserLanguage = 'tr-TR'
  }

  // check if messages object has browser language key like en-GB etc.
  if (Object.prototype.hasOwnProperty.call(messages, browserLanguage)) {
    return browserLanguage
  }

  return 'en-GB'
}

const i18n = new createI18n({
  // locale: lang,
  locale: getCurrentLanguage(), // navigator?.language || 'en-GB',
  fallbackLocale: 'en-GB',
  messages,
  numberFormats,
  datetimeFormats,
})

export default i18n
