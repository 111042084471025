import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

// 3rd patry
import * as Sentry from '@sentry/vue'
import VueGtag from 'vue-gtag'

// core ui
import CoreuiVue from '@coreui/vue-pro'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'

// helpers / services (provide>inject)
import errorHandler from '@/helpers/error-handler'
import mmFormatter from '@/helpers/mm-formatter'
import toISOLocal from '@/helpers/to-iso-local'
import arrayFormatter from '@/helpers/array-formatter'
import arraySorter from '@/helpers/array-sorter'
import abbr from '@/helpers/abbreviate'
import { configureAmplify } from '@/helpers/amplify-helper'

// plugins
import { provideAppToast } from '@/plugins/vue-toastification'
import { provideAppDayjs } from '@/plugins/dayjs'
import { provideMitt } from '@/plugins/mitt'
import i18n from '@/plugins/i18n'

// directives
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

// components
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Dev from '@/components/Dev.vue'
import NoData from '@/components/NoData.vue'
import DSSelect from '@/components/DataSourceSelect.vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

configureAmplify()

const app = createApp(App)
app.use(provideMitt)
app.use(provideAppToast)
app.use(provideAppDayjs)
app.use(store)
app.use(router)
app.use(i18n)
app.use(CoreuiVue)
app.use(FloatingVue, {
  themes: {
    tooltip: {
      triggers: ['hover', 'focus', 'click'],
      delay: {
        show: 600,
      },
    },
  },
})
app.provide('icons', icons)
app.component('Dev', Dev)
app.component('DSSelect', DSSelect)
app.component('CIcon', CIcon)
app.component('v-select', vSelect)
app.component('VueDatePicker', VueDatePicker)
app.component('NoData', NoData)

app.config.globalProperties.$filters = {
  mmFormatter,
  toISOLocal,
  arrayFormatter,
  arraySorter,
  abbr,
}
app.config.globalProperties.$errorHandler = errorHandler

if (process.env.VUE_APP_MODE !== 'development') {
  app.use(VueGtag, {
    config: { id: 'G-E9CZCYG6EQ' },
  })
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: [
          'localhost',
          'https://*.execute-api.eu-central-1.amazonaws.com/*',
          /^\//,
        ],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

app.mount('#app')
