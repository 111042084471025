import mmFormatter from './mm-formatter'

/**
 *
 * @param {Array} data array of data format
 * @param {Boolean} addRaw if true, adds [key]_raw key to the return object
 */
let arrayFormatter = function (data, addRaw) {
  if (!data) {
    return
  }

  let formattedItems = []

  data.forEach((unformatted) => {
    let formatted = {}
    for (const key in unformatted) {
      if (unformatted.hasOwnProperty.call(unformatted, key)) {
        const currentUnformatted = unformatted[key]
        formatted[key] = mmFormatter(currentUnformatted)

        // if current value is 'data type' object and addRaw is true
        // add [key]_raw key to the formatted object
        // like: { revenue: '$12', revenue_raw: {type: 'CURRENCY', value: 12, ...}}
        if (typeof unformatted[key]?.value !== 'undefined' && addRaw) {
          formatted[key + '_raw'] = unformatted[key]
        }
      }
    }

    formattedItems.push(formatted)
  })
  return formattedItems
}

export default arrayFormatter
