<template>
  <CSidebar
    position="fixed"
    class="bg-transition"
    :class="acl?.includes('DEV_MODE') && 'bg-primary'"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="UPDATE_SIDEBAR_VISIBLE({ value: $event })"
  >
    <CSidebarBrand>
      <!-- <CIcon
        custom-class-name="sidebar-brand-full"
        :icon="logoNegative"
        :height="35"
      />
      <CIcon
        custom-class-name="sidebar-brand-narrow"
        :icon="sygnet"
        :height="35"
      /> -->
      <router-link
        to="/"
        class="d-flex align-items-center text-decoration-none"
      >
        <img
          src="@/assets/brand/copyright-capital-logo.png"
          alt="copyright capital logo"
          class="wide-logo"
        />
        <img
          src="@/assets/brand/copyright-capital-logo-mini.png"
          alt="copyright capital logo"
          class="narrow-logo"
        />
        <div class="growth-pipe-sidebar"></div>
        <div class="growth-text text-center fw-lighter text-white mx-2">
          <span class="product-name">{{ productName }}</span>
        </div>
      </router-link>
    </CSidebarBrand>
    <AppSidebarNav :current-member-acl="acl" />
    <CSidebarToggler class="d-none d-lg-flex" @click="TOGGLE_UNFOLDABLE()" />
  </CSidebar>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'
export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(['sidebarUnfoldable', 'sidebarVisible']),
    ...mapState('memberModule', ['acl', 'productName']),
  },
  methods: {
    ...mapMutations(['UPDATE_SIDEBAR_VISIBLE', 'TOGGLE_UNFOLDABLE']),
  },
}
</script>

<style>
.bg-transition {
  transition: all 220ms ease;
}
.product-name {
  line-height: 18px;
  display: inline-block;
  font-size: 15px;
}
.growth-pipe-sidebar {
  border-left: 1px solid white;
  height: 32px;
}
</style>
