/**
 *
 * @param {String} locale string. if not given, takes browser's locale
 * @returns {Object} like: {thousand: '.', decimal: ','}
 */
const getLocaleAwareNumberSeparators = function (locale) {
  const mapperRE = /(.{1})\d{3}(\D)\d$/,
    digitRE = /\d/,
    formattedValue = new Intl.NumberFormat(locale ?? navigator.language).format(
      1000.1,
    )

  // eslint-disable-next-line no-unused-vars
  let [_, thousand, decimal] = mapperRE.exec(formattedValue) ?? [
    null,
    null,
    null,
  ]
  //In case the captured position is number it means there's no thousand separator
  if (digitRE.test(thousand)) thousand = ''
  return { thousand, decimal }
}

export default getLocaleAwareNumberSeparators
