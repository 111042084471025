export default [
  {
    path: 'main',
    name: 'Demo Main',
    component: () => import('@/views/demo/Demo.vue'),
  },
  {
    path: 'demo-form',
    name: 'Demo Form',
    component: () => import('@/views/demo/DemoForm.vue'),
  },
  {
    path: 'vuex-test',
    name: 'VuexTest',
    component: () => import('@/views/demo/VuexTest.vue'),
  },
  {
    path: 'public-page',
    name: 'Public page',
    component: () => import('@/views/demo/PublicPage.vue'),
    meta: {
      requiresAuth: false,
    },
  },
]
