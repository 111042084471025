const state = {
  accountName: null,
  accountLink: null,
  pairedDate: null,
  totalFollowers: null,
  totalMedia: null,
  totalLifetimeReach: null,
  totalProfileViews: null,
  facebookPageId: null,
}

const mutations = {
  SET_ACCOUNT(state, payload) {
    if ('accountName' in payload) {
      state.accountName = payload.accountName
    }
    if ('accountLink' in payload) {
      state.accountLink = payload.accountLink
    }
    if ('pairedDate' in payload) {
      state.pairedDate = payload.pairedDate
    }
    if ('totalFollowers' in payload) {
      state.totalFollowers = payload.totalFollowers
    }
    if ('totalMedia' in payload) {
      state.totalMedia = payload.totalMedia
    }
    if ('totalLifetimeReach' in payload) {
      state.totalLifetimeReach = payload.totalLifetimeReach
    }
    if ('totalProfileViews' in payload) {
      state.totalProfileViews = payload.totalProfileViews
    }
    if ('facebookPageId' in payload) {
      state.facebookPageId = payload.facebookPageId
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}
