<template>
  <CDropdown class="header-account-dropdown" variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0 px-0" :caret="false">
      <CAvatar :src="avatar" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="account-dropdown-menu">
      <CDropdownHeader
        component="h6"
        class="bg-light fw-semibold py-2 rounded-top"
      >
        <div class="d-flex">
          <span class="me-2">Account</span>
        </div>
      </CDropdownHeader>
      <div
        class="text-muted px-3 pt-2 max-width-300-px text-truncate"
        :title="member?.attributes?.email"
      >
        {{ member?.attributes?.email }}
      </div>

      <div
        v-if="acl?.includes('DEV_MODE')"
        class="m-2 p-2 border rounded border-primary shadow-sm fs-13"
        @click.stop
      >
        <div class="text-primary"><b>dev info</b></div>
        <div class="text-warning"><b>member</b></div>
        <pre>{{ member }}</pre>
        <div class="text-warning"><b>acl</b></div>
        <pre>{{ acl }}</pre>
      </div>

      <CDropdownDivider />

      <!-- hidden for desktop, visible for tablet and mobile -->
      <div class="d-block d-lg-none">
        <!-- <CDropdownHeader
          v-if="isAdmin"
          component="h6"
          class="bg-light fw-semibold mt-2"
        >
          Select a user
        </CDropdownHeader> -->

        <!-- admin user select -->
        <!-- <div v-if="isAdmin" class="pt-2 px-3" @click.stop @keyup.stop>
          <AppHeaderAdminMemberListDropdown />
        </div> -->

        <!-- <CDropdownDivider /> -->

        <!-- theme selector -->
        <div class="px-3">
          <AppHeaderThemeSelector />
        </div>
        <CDropdownDivider />
      </div>

      <CDropdownItem @click="onClickLogout">
        <CIcon icon="cil-account-logout" class="me-1" /> Logout
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from '@/assets/images/avatars/default-user.png'
// import AppHeaderAdminMemberListDropdown from './AppHeaderAdminMemberListDropdown'
import AppHeaderThemeSelector from './AppHeaderThemeSelector'

import { mapState, mapActions } from 'vuex'
export default {
  name: 'AppHeaderDropdownAccnt',
  components: {
    // AppHeaderAdminMemberListDropdown,
    AppHeaderThemeSelector,
  },
  data() {
    return {
      avatar: avatar,
    }
  },
  methods: {
    ...mapActions('memberModule', ['LOGOUT_MEMBER']),
    onClickLogout() {
      this.LOGOUT_MEMBER()
    },
  },
  computed: {
    ...mapState('memberModule', ['acl', 'member', 'isAdmin']),
  },
}
</script>

<style lang="scss">
.account-dropdown-menu {
  max-height: calc(100vh - 64px);
  overflow-y: auto;
  padding-top: 0;
  max-width: 300px;
}
</style>
